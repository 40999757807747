@import url(https://fonts.googleapis.com/css2?family=Hurricane&display=swap);
@import url(https://fonts.googleapis.com/css?family=Readex+Pro:400);
.name{
        font-family: 'Hurricane', cursive;
        font-size: 140px;
        line-height: 180px;  
        
    }

    @media only screen and (max-width: 500px) {

      .name{
          font-family: 'Hurricane', cursive;
          font-size: 60px;
          line-height: 60px;  
      }

      
   }
    
  
        

